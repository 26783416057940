import React from 'react';
import styled from 'styled-components';
import Background from '../../assets/img/background.jpg'
import { FaWhatsapp, FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa'; // Ícones das redes sociais
import { Link } from 'react-router-dom';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
`;

const ContactItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const ContactTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
`;

const ContactItem = styled.p`
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
`;

const ContactLink = styled.a`
  text-decoration: none;
  color: #333;

  &:hover {
    color: #f50;
  }
`;

const DirectLink = styled(Link)`
  text-decoration: none;
  color: #333;

  &:hover {
    color: #f50;
  }
`;


const ContactInfo = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  text-align: center;
`;

const InfoItem = styled.p`
  font-size: 1.5rem;
  color: #555;
  margin: 0;
`;

const SocialLinks = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialLink = styled.a`
  font-size: 2rem;
  color: #555;
  transition: color 0.3s;
  margin-bottom: 25px;

  &:hover {
    color: #f50;
    font-size: 2.1rem;
    transition: all 0.05s ease-out;
  }
`;

const Contato = () => {
  return (
    <ContactContainer>
      <ContactInfo>
        <ContactTitle>O Bardo Produtora</ContactTitle>
          <InfoItem>19.946.633/0001-15</InfoItem>
          
        <ContactItemContainer>
          <ContactItem>Email: </ContactItem>
          <InfoItem> 
            <ContactLink href="mailto:obardoprodutora@gmail.com">obardoprodutora@gmail.com</ContactLink></InfoItem>

        </ContactItemContainer>



        {/* Links para redes sociais */}
        <SocialLinks>
          <SocialLink href="https://www.facebook.com/obardoproducoesmusicais" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/obardoprodutora" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </SocialLink>
          <SocialLink href="https://www.youtube.com/@obardoprodutora" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </SocialLink>
          <SocialLink href="https://wa.me/5541996259946" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
          </SocialLink>
        </SocialLinks>
        <InfoItem>Curitiba - PR</InfoItem>
        <InfoItem>
          <DirectLink 
            to="/politica-de-privacidade" 
            style={{textDecoration: "none"}}>
              Política de Privacidade
          </DirectLink>
        </InfoItem>
      </ContactInfo>
    </ContactContainer>
  );
};

export default Contato;
