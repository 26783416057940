import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const Videos = () => {
  return (
    <Gallery>
      <VideoWrapper>
        <iframe
          src="https://www.youtube.com/embed/gfHqcnaCWJc"
          title="Vídeo 1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
      <VideoWrapper>
        <iframe
          src="https://www.youtube.com/embed/-6cNq_q9jfo"
          title="Vídeo 1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
      <VideoWrapper>
        <iframe
          src="https://www.youtube.com/embed/wGaazO_7cgE"
          title="Vídeo 1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
      <VideoWrapper>
        <iframe
          src="https://www.youtube.com/embed/oqL8LBKJP-A"
          title="Vídeo 1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
      <VideoWrapper>
        <iframe
          src="https://www.youtube.com/embed/_gdHnfBo_RA"
          title="Vídeo 1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
      <VideoWrapper>
        <iframe
          src="https://www.youtube.com/embed/jtxD2GnA6gg"
          title="Vídeo 1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
      {/* Adicione mais vídeos */}
    </Gallery>
  );
};

export default Videos;

// <iframe width="695" height="391" src="https://www.youtube.com/embed/gfHqcnaCWJc" title="Clipe de Highlights do jogador Enrico Borio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>