import React, { useState } from 'react';
import styled from 'styled-components';
import IMG from './IMG'; // Componente de imagem reutilizável

import image1 from '../../assets/img/portifolio/1.jpg';
import image2 from '../../assets/img/portifolio/2.jpg';
import image3 from '../../assets/img/portifolio/3.jpg';
import image4 from '../../assets/img/portifolio/4.jpg';
import image5 from '../../assets/img/portifolio/5.jpg';
import image6 from '../../assets/img/portifolio/6.jpg';
import image7 from '../../assets/img/portifolio/7.jpg';
import image8 from '../../assets/img/portifolio/8.jpg';
import image9 from '../../assets/img/portifolio/9.jpg';
import image10 from '../../assets/img/portifolio/10.jpg';

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 10px;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
`;

const Fotos = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Gallery>
        <IMG src={image1} alt="Foto 1" caption="Captação de registro fotografico do Festival Massa FM 2016" onClick={() => openModal(image1)} />
        <IMG src={image2} alt="Foto 2" caption="Captação de registro fotografico do Festival Massa FM 2016" onClick={() => openModal(image2)} />
        <IMG src={image3} alt="Foto 3" caption="Foto divulgação da Banda B.Okay" onClick={() => openModal(image3)} />
        <IMG src={image4} alt="Foto 4" caption="Captação de registro fotografico do Festival Massa FM 2016" onClick={() => openModal(image4)} />
        <IMG src={image5} alt="Foto 5" caption="Captação de registro fotografico do Festival Country Festival 2018" onClick={() => openModal(image5)} />
        <IMG src={image6} alt="Foto 6" caption="Captação de registro fotografico do Festival Country Festival 2018" onClick={() => openModal(image6)} />
        <IMG src={image7} alt="Foto 7" caption="Captação de registro fotografico de festa particular no Salão Araucária" onClick={() => openModal(image7)} />
        <IMG src={image8} alt="Foto 8" caption="Landscape conceitual de um deposito de lixo hospitalar subaquático no Paraná" onClick={() => openModal(image8)} />
        <IMG src={image9} alt="Foto 9" caption="Captação de registro fotográfico da Banda Big Time Orchestra em 2013" onClick={() => openModal(image9)} />
        <IMG src={image10} alt="Foto 10" caption="Captação de registro fotográfico do Festival de Teatro de Curitiba em 2014" onClick={() => openModal(image10)} />
      </Gallery>

      {selectedImage && (
        <Modal onClick={closeModal}>
          <ModalImage src={selectedImage} alt="Imagem ampliada" />
          <CloseButton onClick={closeModal}>&times;</CloseButton>
        </Modal>
      )}
    </>
  );
};

export default Fotos;
