import React, { useState } from 'react';
import styled from 'styled-components';
import EventDetail from './EventDetail'; // Importando o modal de detalhes

import * as DMR2023 from '../../assets/img/portifolio/DMR2023/index'; // Importando as imagens do evento Dia Mundial do Rock 2023
import * as DMR2024 from '../../assets/img/portifolio/DMR2024/index'; // Importando as imagens do evento Dia Mundial do Rock 2024
import * as NATALHR2023 from '../../assets/img/portifolio/NATALHR2023/index'; // Importando as imagens do evento Natal HardRock Café Curitiba 2023

const EventCard = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const EventThumbnail = styled.img`
  width: 50%;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const EventTitle = styled.h3`
  font-size: 2rem;
  color: #000;
  margin: 0 0 10px 0;
`;

const EventDescription = styled.p`
  font-size: 1.5rem;
  color: #666;
`;

const EventSubtitle = styled.p`
  font-size: 1.2rem;
  color: #000;
`;

const Eventos = () => {
  const [selectedEvent, setSelectedEvent] = useState(null); // Estado para controlar o evento selecionado

  const openEventDetail = (event) => {
    setSelectedEvent(event); // Define o evento selecionado para abrir o modal
  };

  const closeEventDetail = () => {
    setSelectedEvent(null); // Fecha o modal
  };

  const events = [
    {
      title: 'Dia Mundial do Rock - 2024',
      description: 'Produção da Segunda Edição do evento de comemoração ao Dia Mundial do Rock no Shopping Jockey Plaza em parceria com a Produtora Pocket Store. Shows de rock, karaokê com banda ao vivo e participação de grandes nomes da música Curitibana.',
      thumbnail: DMR2024.eventPhoto1,
      photos: [
        DMR2024.eventPhoto1, 
        DMR2024.eventPhoto2, 
        DMR2024.eventPhoto3,
        DMR2024.eventPhoto4,
        DMR2024.eventPhoto5,
        DMR2024.eventPhoto6,
        DMR2024.eventPhoto7,
        DMR2024.eventPhoto8,
        DMR2024.eventPhoto9,
        DMR2024.eventPhoto10,
        DMR2024.eventPhoto11,
        DMR2024.eventPhoto12,
        DMR2024.eventPhoto13,
        DMR2024.eventPhoto14,
        DMR2024.eventPhoto15,
        DMR2024.eventPhoto16,
        DMR2024.eventPhoto17,
        DMR2024.eventPhoto18,
        DMR2024.eventPhoto19,
        DMR2024.eventPhoto20,],
    },
    {
      title: 'Natal HardRock Café Curitiba - 2023',
      description: 'Produção dos espetáculos da temporada de natal do HardRock Café Curitiba de 2023.',
      thumbnail: NATALHR2023.eventPhoto1,
      photos: [
        NATALHR2023.eventPhoto1, 
        NATALHR2023.eventPhoto2, 
        NATALHR2023.eventPhoto3,
        NATALHR2023.eventPhoto4,
        NATALHR2023.eventPhoto5,
        NATALHR2023.eventPhoto6,
        NATALHR2023.eventPhoto7,
        NATALHR2023.eventPhoto8,
        NATALHR2023.eventPhoto9,
        NATALHR2023.eventPhoto10,
        NATALHR2023.eventPhoto11,
        NATALHR2023.eventPhoto12,
        NATALHR2023.eventPhoto13,
        NATALHR2023.eventPhoto14,
        NATALHR2023.eventPhoto15,
        NATALHR2023.eventPhoto16,
        NATALHR2023.eventPhoto17,
        NATALHR2023.eventPhoto18,
        NATALHR2023.eventPhoto19,
        NATALHR2023.eventPhoto20,
      ],
    },
    {
      title: 'Dia Mundial do Rock - 2023',
      description: 'Produção do evento de comemoração ao Dia Mundial do Rock no Shopping Jockey Plaza em parceria com a Produtora Pocket Store. Shows de rock, karaokê com banda ao vivo e participação de grandes nomes da música Curitibana.',
      thumbnail: DMR2023.eventPhoto1,
      photos: [
        DMR2023.eventPhoto1, 
        DMR2023.eventPhoto2, 
        DMR2023.eventPhoto3,
        DMR2023.eventPhoto4,
        DMR2023.eventPhoto5,
        DMR2023.eventPhoto6,
        DMR2023.eventPhoto7,
        DMR2023.eventPhoto8,
        DMR2023.eventPhoto9,
        DMR2023.eventPhoto10,
        DMR2023.eventPhoto11,
        DMR2023.eventPhoto12,
        DMR2023.eventPhoto13,
        DMR2023.eventPhoto14,
        DMR2023.eventPhoto15,
        DMR2023.eventPhoto16,
        DMR2023.eventPhoto17,
        DMR2023.eventPhoto18,
        DMR2023.eventPhoto19,
        DMR2023.eventPhoto20,
      ],
    },
    

  ];

  return (
    <>
      {events.map((event, index) => (
        <EventCard key={index} onClick={() => openEventDetail(event)}>
          <EventTitle>{event.title}</EventTitle>
          <EventDescription>{event.description}</EventDescription>
          <EventSubtitle>Clique para ver as fotos do evento</EventSubtitle>
          <EventThumbnail src={event.thumbnail} alt={event.title} />
        </EventCard>
      ))}

      {selectedEvent && (
        <EventDetail event={selectedEvent} onClose={closeEventDetail} />
      )}
    </>
  );
};

export default Eventos;
