// Dashboard.js

import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const DashboardTitle = styled.h2`
  margin-bottom: 20px;
`;

const DashboardOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const OptionButton = styled.button`
  margin: 10px 0;
  padding: 15px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Dashboard = () => {
  const navigate = useNavigate();

  const handleBlogDashboard = () => {
    navigate("/blogDashboard"); // Navega para a página de criação de posts
  };

  return (
    <DashboardContainer>
      <DashboardTitle>Dashboard</DashboardTitle>
      <DashboardOptions>
        <OptionButton onClick={handleBlogDashboard}>Blog</OptionButton>
      </DashboardOptions>
    </DashboardContainer>
  );
};

export default Dashboard;
