import React, { useState } from 'react';

import background from '../../assets/img/background.jpg';
import styled from 'styled-components';
import Fotos from './Fotos';
import Videos from './Videos';
import Sites from './Sites';
import Eventos from './Eventos';
import Musicas from './Musicas';

const PageContainer = styled.div`
  min-height: 100vh;
  padding: 20px;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: 'Amatic SC', cursive;
`;

const Container = styled.div`
  font-family: 'Amatic SC', cursive;
  background-color: rgba(0, 0, 0, 0.7); 
  border-radius: 10px;  
`;

const TabMenu = styled.ul`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  list-style: none;
  padding: 0;
  margin: 20px 0;
  justify-content: center;

  @media screen and (max-width: 768px) {
   flex-direction: column;
  }
`;

const TabMenuItem = styled.li`
  margin: 0 10px;
  padding: 10px 20px;
  
  cursor: pointer;
  border-bottom: ${(props) => (props.active ? '3px solid #ff6347' : 'none')};
  color: ${(props) => (props.active ? '#ff6347' : '#eee')};
  &:hover {
    color: #ff6347;
  }
`;

const ContentSection = styled.div`
padding: 20px;
  padding-bottom: 50px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  color: white;
  margin-top: 5vh;
  margin-bottom: 5vh;
`;

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState('Eventos');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Eventos':
        return <Eventos />;
      case 'Sites':
        return <Sites />;
      case 'Músicas':
        return <Musicas />;
      case 'Vídeos':
        return <Videos />;
      case 'Fotos':
        return <Fotos />;
      default:
        return null;
    }
  };

  return (
    <PageContainer>
      <Container>
      <TabMenu>
        {[
          'Eventos', 
          'Sites', 
          'Músicas',
          'Vídeos', 
          'Fotos', 
        ].map((tab) => (
          <TabMenuItem
            key={tab}
            active={activeTab === tab}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </TabMenuItem>
        ))}
      </TabMenu>
      <ContentSection>{renderTabContent()}</ContentSection>
      </Container>
    </PageContainer>
  );
};

export default Portfolio;
