import React from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const StyledImage = styled.img`
  max-width: 100%;
  border-radius: ${(props) => props.borderRadius || '8px'};
  object-fit: ${(props) => props.objectFit || 'cover'};
  box-shadow: ${(props) => props.shadow || '0 4px 8px rgba(0, 0, 0, 0.1)'};
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: ${(props) => (props.onClick ? 'scale(1.05)' : 'none')};
  }
`;

const ImageCaption = styled.figcaption`
  font-size: 0.9rem;
  color: #fff;
  text-align: center;
  margin-top: 8px;
  font-style: italic;
`;

const IMG = ({ src, alt, caption, width, height, borderRadius, objectFit, shadow, onClick }) => {
  return (
    <ImageWrapper width={width} height={height} onClick={onClick}>
      <StyledImage
        src={src}
        alt={alt || 'Imagem'}
        loading="lazy"
        borderRadius={borderRadius}
        objectFit={objectFit}
        shadow={shadow}
        onClick={onClick} // Adicionando evento de clique
      />
      {caption && <ImageCaption>{caption}</ImageCaption>}
    </ImageWrapper>
  );
};

export default IMG;
