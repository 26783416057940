import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../Firebase'; // Certifique-se de que o caminho está correto
import { Link } from 'react-router-dom';
import background from '../../assets/img/background.png';
import { format } from 'date-fns'; // Biblioteca para formatação de datas
import { Helmet } from "react-helmet";

const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: url(${background});
  min-height: 100vh;
`;

const BlogTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 4rem;
  font-weight: bold;
  color: white;
`;

const PostList = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PostItem = styled.div`
  padding: 15px;
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const PostLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  font-size: 2rem;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 800px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
`;

/* Ajuste para o contêiner de abas */
const TabsContainer = styled.div`
  display: flex;
  justify-content: center; /* Centraliza o menu na versão desktop */
  margin-bottom: 20px;
  width: 100%;
  white-space: nowrap; /* Impede quebras de linha nos botões */
  
  /* Estilos para telas menores */
  @media (max-width: 768px) {
    justify-content: flex-start; /* Ajusta para alinhar à esquerda em dispositivos móveis */
    overflow-x: auto; /* Permite rolagem horizontal */
    scrollbar-width: thin; /* Reduz a largura da barra de rolagem */
    padding-bottom: 10px;
    -ms-overflow-style: none; /* Remove a barra de rolagem no IE */
  
    /* Remove as barras de rolagem no Chrome, Safari e Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Tab = styled.button`
  background-color: ${(props) => (props.active ? '#f50' : '#f0f0f0')};
  color: ${(props) => (props.active ? 'white' : '#000')};
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 8px;
  white-space: nowrap; /* Evita quebra de texto nos botões */

  &:hover {
    background-color: #f50;
    color: white;
  }
`;

const PostItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column; /* Para dispositivos móveis, empilhar o conteúdo */
  }
`;

const PostInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
`;

const PostImgContainer = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 100%; /* Ocupa a largura total do container no mobile */
    height: auto; /* Ajusta a altura para manter a proporção */
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const PostTitle = styled.h2`
  color: #333;
  font-size: 2rem;
  font-weight: bold;
`;

const PostSubTitle = styled.p`
  color: #333;
  font-size: 1rem;
  font-weight: thin;
`;

const PostAuthor = styled.p`
  color: #333;
  font-size: 1rem;
  font-weight: thin;
  margin-top: 10px;
`;

const PostImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
    height: 80px;
  }
`;

// Novo elemento para exibir o total de posts
const TotalPosts = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: white;
`;

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25); // Número de posts por página
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Todos'); // Estado para a categoria selecionada

  useEffect(() => {
    const fetchPosts = async () => {
      const q = query(collection(db, 'posts'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const postsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(postsData);
    };

    fetchPosts();
  }, []);

  // Formatar a data para exibição
  const formatDate = (date) => {
    return format(new Date(date.seconds * 1000), 'dd MMM yyyy');
  };

  // Filtrar posts com base no termo de busca e na categoria selecionada
  const filteredPosts = posts.filter((post) => {
    const matchesSearchTerm = post.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory =
      selectedCategory === 'Todos' || post.category === selectedCategory;
    return matchesSearchTerm && matchesCategory;
  });

  // Lógica de paginação
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  return (
    <BlogContainer>
      <Helmet>
        <meta
          name="description"
          content="Seja bem vindo ao Blog d'O Bardo. Descubra técnicas, dicas e venha conosco na fascinante jornada do trabalho com cultura e arte no Brasil!"
        />
        <title>Blog - O Bardo Produtora</title>
      </Helmet>
      <BlogTitle>A TAVERNA DO BARDO</BlogTitle>

      {/* Exibe o número total de posts */}
      
      <SearchInput
        type="text"
        placeholder="Procurar posts..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        />

      {/* Tabs para filtrar por categoria */}
      <TabsContainer>
        {['Todos', 'Notícias', 'Técnico', 'Tutoriais', 'Opinião', 'Dicas'].map((category) => (
          <Tab
            key={category}
            active={selectedCategory === category}
            onClick={() => setSelectedCategory(category)}
            >
            {category}
          </Tab>
        ))}
      </TabsContainer>

      <PostList>
        {currentPosts.map((post) => (
          <PostItem key={post.id}>
            <PostLink to={`/post/${post.id}`}>
              <PostItemContainer>
                <PostInfoColumn>
                  <PostTitle>{post.title}</PostTitle>
                  <PostSubTitle>{post.subtitle}</PostSubTitle>
                  <PostImgContainer>
                    <PostImage src={post.imageUrl} alt={post.altText} />
                  </PostImgContainer>
                  <PostAuthor>{post.author}, {formatDate(post.createdAt)}</PostAuthor>
                </PostInfoColumn>
              </PostItemContainer>
            </PostLink>
          </PostItem>
        ))}
      </PostList>

        <TotalPosts>Total de posts: {filteredPosts.length}</TotalPosts>
      <PaginationContainer>
        <PaginationButton
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          >
          Anterior
        </PaginationButton>
        <PaginationButton
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          >
          Próxima
        </PaginationButton>
      </PaginationContainer>
    </BlogContainer>
  );
};

export default Blog;
