import React from 'react';
import styled from 'styled-components';

const MusicWrapper = styled.div`
  margin-bottom: 20px;
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const Musicas = () => {
  return (
    <Gallery>
      <MusicWrapper>
        <iframe
          src="https://www.youtube.com/embed/qA7whfzacTQ"
          title="Mariana - Banda Gorros"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </MusicWrapper>
      <MusicWrapper>
        <iframe
          src="https://www.youtube.com/embed/wvxCGxB9nkk"
          title="Cidadão de Bem - Banda Gorros"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </MusicWrapper>
      <MusicWrapper>
        <iframe
          src="https://www.youtube.com/embed/TZvINS5b-YU"
          title="Rafaela - Banda Gorros"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </MusicWrapper>
      <MusicWrapper>
        <iframe
          src="https://www.youtube.com/embed/Eu5K7X95AGE"
          title="Fim do Brasil - Banda Gorros"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </MusicWrapper>
      <MusicWrapper>
        <iframe
          src="https://www.youtube.com/embed/oxJw4Ou6eAM"
          title="Mariana Pt. 2 - Banda Gorros"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </MusicWrapper>
      <MusicWrapper>
        <iframe
          src="https://www.youtube.com/embed/colrXAB9S6w"
          title="Mariana - Versão Extendida - Banda Gorros"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </MusicWrapper>
      {/* Adicione mais músicas */}
    </Gallery>
  );
};

export default Musicas;
