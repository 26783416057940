import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../Firebase'; // Certifique-se de que o caminho está correto
import background from '../../assets/img/background.png';

const PostContainer = styled.div`
  background-image: url(${background});
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
`;

const PostContentContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin-bottom: 50px;
  gap: 20px;
`;

const PostContent = styled.div`
  flex: 3;
  background-color: #CCC;
  padding: 20px;
  border-radius: 8px;
  font-size: 1.5rem;
  font-family: "Afacad Flux", sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    loading: lazy; /* Implementação do lazy loading */
  }
`;

const PostTitle = styled.h1`
  margin-bottom: 10px;
  font-family:"Amatic SC";
`;

const PostSubtitle = styled.h3`
  margin-bottom: 20px;
  font-family:"Amatic SC";
  color: #666;
`;

const PostMeta = styled.p`
  margin-bottom: 10px;
  color: #888;
`;

const PostImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  width: 600px;
  height: auto;
  loading: lazy;

  /* Definindo width e height explícitos */
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const AdContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState('');
  const [content, setContent] = useState(''); 

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postDoc = await getDoc(doc(db, 'posts', postId));
        if (postDoc.exists()) {
          const postData = postDoc.data();
          setPost(postData);
          
          if (postData.contentUrl) {
            const response = await fetch(postData.contentUrl);
            const htmlContent = await response.text();
            setContent(htmlContent);
          }
        } else {
          setError('Post não encontrado.');
        }
      } catch (err) {
        setError('Erro ao carregar o post. Tente novamente.');
      }
    };

    if (postId) {
      fetchPost();
    }
  }, [postId]);

  if (!post) {
    return <p>Carregando...</p>;
  }

  return (
    <PostContainer>
      <PostContentContainer>
        <PostContent>
          <PostTitle>{post.title}</PostTitle>
          {post.subtitle && <PostSubtitle>{post.subtitle}</PostSubtitle>}
          {post.imageUrl && (
            <PostImage
              src={post.imageUrl.replace('.png', '.webp')}
              alt={post.altText}
              width="600"
              height="400" /* Ajuste de width e height */
              srcSet={`${post.imageUrl.replace('.png', '.webp')} 600w, ${post.imageUrl.replace('.png', '-small.webp')} 300w`}
              sizes="(max-width: 600px) 300px, 600px" /* Imagens responsivas */
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <PostMeta>
            Publicado por {post.author} em {new Date(post.publicationDate).toLocaleDateString()}
          </PostMeta>
          {post.category && <PostMeta>Categoria: {post.category}</PostMeta>}
          {post.tags && (
            <PostMeta>
              Tags: {post.tags.map((tag, index) => (
                <span key={index}>#{tag} </span>
              ))}
            </PostMeta>
          )}
        </PostContent>
      </PostContentContainer>
    </PostContainer>
  );
};

export default BlogPost;
