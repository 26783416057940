import React, { useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Importa o CSS do SunEditor
import styled from 'styled-components';
import { addDoc, collection } from 'firebase/firestore';
import { db, storage } from "../../../Firebase";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const CreatePostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const CreatePostForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const TextArea = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Select = styled.select`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #218838;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState(''); // Subtítulo opcional
  const [content, setContent] = useState(''); // Corpo do post
  const [category, setCategory] = useState(''); // Categoria
  const [tags, setTags] = useState(''); // Tags (opcional)
  const [metaTitle, setMetaTitle] = useState(''); // Meta Título (SEO)
  const [metaDescription, setMetaDescription] = useState(''); // Meta Descrição (SEO)
  const [slug, setSlug] = useState(''); // Slug personalizado
  const [image, setImage] = useState(null); // Imagem de destaque
  const [altText, setAltText] = useState(''); // Alt Text da imagem
  const [author, setAuthor] = useState(''); // Autor
  const [publicationDate, setPublicationDate] = useState(''); // Data de publicação
  const [status, setStatus] = useState('Rascunho'); // Status do post
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Função para upload da imagem de destaque
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  // Função para upload do conteúdo como arquivo HTML no Firebase Storage
  const uploadContent = async (content) => {
    const blob = new Blob([content], { type: 'text/html' });
    const fileRef = ref(storage, `posts/${uuidv4()}.html`);
    await uploadBytes(fileRef, blob);
    return getDownloadURL(fileRef);
  };

  const uploadImage = async () => {
    if (image) {
      const imageRef = ref(storage, `images/${uuidv4()}`);
      await uploadBytes(imageRef, image);
      return getDownloadURL(imageRef);
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !content || !category || !author || !publicationDate || !image || !altText) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    try {
      // Faz o upload do conteúdo e da imagem para o Firebase Storage
      const contentUrl = await uploadContent(content);
      const imageUrl = await uploadImage();

      // Adiciona o post ao Firestore com a URL do conteúdo e da imagem
      await addDoc(collection(db, 'posts'), {
        title,
        subtitle,
        contentUrl, // Armazena a URL do conteúdo
        category,
        tags: tags.split(',').map(tag => tag.trim()), // Converte as tags para array
        metaTitle,
        metaDescription,
        slug,
        imageUrl,
        altText,
        author,
        publicationDate,
        status,
        createdAt: new Date(),
      });

      // Após a criação, navega de volta para o dashboard
      navigate('/dashboard');
    } catch (err) {
      setError('Erro ao criar o post. Tente novamente.');
      console.error('Erro ao criar post:', err);
    }
  };

  return (
    <CreatePostContainer>
      <h2>Criar Novo Post</h2>
      <CreatePostForm onSubmit={handleSubmit}>
        {/* Título do post */}
        <Input
          type="text"
          placeholder="Título do Post (obrigatório)"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        {/* Subtítulo do post */}
        <Input
          type="text"
          placeholder="Subtítulo (opcional)"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
        />

        {/* Editor de texto SunEditor */}
        <SunEditor
          setContents={content}
          onChange={setContent}
          placeholder="Escreva o conteúdo do post aqui..."
          setOptions={{
            buttonList: [
              ['bold', 'underline', 'italic'], // Formatação básica
              ['font', 'align', 'list'], // Fontes, alinhamento, listas
              ['link', 'image', 'video'], // Links e imagens
              ['undo', 'redo'], // Undo e Redo
            ],
          }}
        />

        {/* Categoria */}
        <Select value={category} onChange={(e) => setCategory(e.target.value)} required>
          <option value="">Selecione uma categoria</option>
          <option value="Notícias">Notícias</option>
          <option value="Técnico">Técnico</option>
          <option value="Tutoriais">Tutoriais</option>
          <option value="Dicas">Dicas</option>
          <option value="Opinião">Opinião</option>
        </Select>

        {/* Tags */}
        <Input
          type="text"
          placeholder="Tags (separadas por vírgulas)"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />

        {/* Meta Título e Descrição */}
        <Input
          type="text"
          placeholder="Meta Título (opcional)"
          value={metaTitle}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
        <TextArea
          placeholder="Meta Descrição (opcional)"
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
          maxLength="160"
        />
        <Input
          type="text"
          placeholder="Slug personalizado (opcional)"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />

        {/* Imagem de destaque */}
        <Input type="file" accept="image/*" onChange={handleImageUpload} required />
        <Input
          type="text"
          placeholder="Alt Text da Imagem de Destaque (obrigatório)"
          value={altText}
          onChange={(e) => setAltText(e.target.value)}
          required
        />

        {/* Autor e Data de Publicação */}
        <Input
          type="text"
          placeholder="Autor do Post (obrigatório)"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
        />
        <Input
          type="datetime-local"
          placeholder="Data e Hora de Publicação (obrigatório)"
          value={publicationDate}
          onChange={(e) => setPublicationDate(e.target.value)}
          required
        />

        {/* Status do Post */}
        <Select value={status} onChange={(e) => setStatus(e.target.value)} required>
          <option value="Rascunho">Rascunho</option>
          <option value="Publicado">Publicado</option>
          <option value="Pendente de Revisão">Pendente de Revisão</option>
        </Select>

        <Button type="submit">Criar Post</Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </CreatePostForm>
    </CreatePostContainer>
  );
};

export default CreatePost;
