import React, { useState, useEffect } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Importa o CSS do SunEditor
import styled from 'styled-components';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../../../Firebase'; // Certifique-se de que o caminho está correto
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom'; // useParams para obter o postId da URL

const UpdatePostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
  max-width: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const UpdatePostForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const TextArea = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Select = styled.select`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #218838;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const DeleteButton = styled.button`
  padding: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #c82333;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

// Função para upload de conteúdo no Firebase Storage
const uploadContent = async (content) => {
  const blob = new Blob([content], { type: 'text/html' });
  const fileRef = ref(storage, `posts/${uuidv4()}.html`);
  await uploadBytes(fileRef, blob);
  return getDownloadURL(fileRef);
};

const uploadImage = async (image) => {
  if (image) {
    const imageRef = ref(storage, `images/${uuidv4()}`);
    await uploadBytes(imageRef, image);
    return getDownloadURL(imageRef);
  }
  return '';
};

const UpdatePost = () => {
  const { postId } = useParams(); // Obtém o postId da URL
  const [post, setPost] = useState(null); // Armazena o post completo
  const [title, setTitle] = useState(''); // Inicializa vazio até o post carregar
  const [subtitle, setSubtitle] = useState('');
  const [content, setContent] = useState(''); // Inicializa como vazio, será carregado
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [slug, setSlug] = useState('');
  const [image, setImage] = useState(null); // Imagem de destaque
  const [altText, setAltText] = useState('');
  const [author, setAuthor] = useState('');
  const [publicationDate, setPublicationDate] = useState('');
  const [status, setStatus] = useState('Rascunho');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Carrega o post completo ao iniciar
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postDoc = await getDoc(doc(db, 'posts', postId)); // Busca o post pelo ID
        if (postDoc.exists()) {
          const postData = postDoc.data();
          setPost(postData);
          setTitle(postData.title);
          setSubtitle(postData.subtitle || '');
          setCategory(postData.category || '');
          setTags(postData.tags ? postData.tags.join(', ') : '');
          setMetaTitle(postData.metaTitle || '');
          setMetaDescription(postData.metaDescription || '');
          setSlug(postData.slug || '');
          setAltText(postData.altText || '');
          setAuthor(postData.author || '');
          setPublicationDate(postData.publicationDate || '');
          setStatus(postData.status || 'Rascunho');
          if (postData.contentUrl) {
            const response = await fetch(postData.contentUrl);
            if (!response.ok) {
              throw new Error(`Erro ao buscar o conteúdo: ${response.statusText}`);
            }
            const contentText = await response.text();
            setContent(contentText); // Define o conteúdo do editor
          }
        } else {
          setError('Post não encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar o post:', error);
        setError('Erro ao carregar o post. Tente novamente.');
      }
    };

    if (postId) {
      fetchPost(); // Busca o post quando o postId estiver disponível
    }
  }, [postId]);

  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Tem certeza de que deseja excluir este post?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'posts', postId)); // Exclui o documento
        navigate('/dashboard'); // Redireciona para o dashboard após a exclusão
      } catch (error) {
        console.error('Erro ao excluir post:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !content || !category || !author || !publicationDate || !image || !altText) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    try {
      const contentUrl = await uploadContent(content);
      const imageUrl = await uploadImage(image);

      // Atualiza o post no Firestore com a nova URL do conteúdo e da imagem
      const postDoc = doc(db, 'posts', postId);
      await updateDoc(postDoc, {
        title,
        subtitle,
        contentUrl,
        category,
        tags: tags.split(',').map(tag => tag.trim()),
        metaTitle,
        metaDescription,
        slug,
        imageUrl,
        altText,
        author,
        publicationDate,
        status
      });

      // Após a atualização, navega de volta para o dashboard
      navigate('/dashboard');
    } catch (err) {
      setError('Erro ao atualizar o post. Tente novamente.');
      console.error('Erro ao atualizar post:', err);
    }
  };

  if (!post) {
    return <p>Carregando...</p>; // Mostra carregando até o post ser carregado
  }

  return (
    <UpdatePostContainer>
      <h2>Atualizar Post</h2>
      <UpdatePostForm onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Título do Post (obrigatório)"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <Input
          type="text"
          placeholder="Subtítulo (opcional)"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
        />

        {/* SunEditor com o conteúdo carregado */}
        <SunEditor
          setContents={content} // Define o conteúdo do editor
          onChange={setContent}
          placeholder="Atualize o conteúdo do post aqui..."
          setOptions={{
            height: 'auto',
            minHeight: '200px',
            buttonList: [
              ['bold', 'underline', 'italic'],
              ['font', 'align', 'list'],
              ['link', 'image', 'video'],
              ['undo', 'redo'],
            ],
          }}
        />

        <Select value={category} onChange={(e) => setCategory(e.target.value)} required>
          <option value="">Selecione uma categoria</option>
          <option value="Notícias">Notícias</option>
          <option value="Técnico">Técnico</option>
          <option value="Tutoriais">Tutoriais</option>
          <option value="Opinião">Opinião</option>
        </Select>

        <Input
          type="text"
          placeholder="Tags (separadas por vírgulas)"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />

        {/* Meta Título e Meta Descrição */}
        <Input
          type="text"
          placeholder="Meta Título (opcional)"
          value={metaTitle}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
        <TextArea
          placeholder="Meta Descrição (opcional)"
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
          maxLength="160"
        />
        <Input
          type="text"
          placeholder="Slug personalizado (opcional)"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />

        {/* Imagem de destaque */}
        <Input type="file" accept="image/*" onChange={handleImageUpload} />
        <Input
          type="text"
          placeholder="Alt Text da Imagem de Destaque (obrigatório)"
          value={altText}
          onChange={(e) => setAltText(e.target.value)}
          required
        />

        {/* Autor e Data de Publicação */}
        <Input
          type="text"
          placeholder="Autor do Post (obrigatório)"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
        />
        <Input
          type="datetime-local"
          placeholder="Data e Hora de Publicação (obrigatório)"
          value={publicationDate}
          onChange={(e) => setPublicationDate(e.target.value)}
          required
        />

        {/* Status do Post */}
        <Select value={status} onChange={(e) => setStatus(e.target.value)} required>
          <option value="Rascunho">Rascunho</option>
          <option value="Publicado">Publicado</option>
          <option value="Pendente de Revisão">Pendente de Revisão</option>
        </Select>

        <Button type="submit">Atualizar Post</Button>
        <DeleteButton onClick={handleDelete}>Excluir Post</DeleteButton>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </UpdatePostForm>
    </UpdatePostContainer>
  );
};

export default UpdatePost;
