import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FiMenu } from 'react-icons/fi';
import logo from '../assets/img/bardologo.png';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #000;
  z-index: 1000; /* Certifique-se de que o cabeçalho fique acima de outros elementos */
  position: fixed; /* Fixar o cabeçalho no topo da página */
  top: 0;
  left: 0;
  width: 100%; /* Garantir que o cabeçalho ocupe toda a largura da página */
`;

const Logo = styled.img`
  height: 50px;
`;

const MenuIcon = styled(FiMenu)`
  color: white;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  padding-right: 5vw;
  right: 1rem;
  top: 4.5vh; /* Ajustar para o alinhamento correto dentro do cabeçalho */
`;

const Nav = styled.nav`
  ul {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #000;
    position: absolute;
    top: 70px; /* Certificar que o menu apareça logo abaixo do cabeçalho */
    right: 0;
    width: 100%;
    text-align: center;

    li {
      margin: 1rem 0;

      a {
        text-decoration: none;
        color: white;
        font-family: 'Amatic SC', cursive;
        font-size: 1.5rem;
      }

      a:hover {
        color: #ff6347;
      }
    }
  }
`;

const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src={logo} alt="Logo O Bardo" />
      </Link>
      <MenuIcon onClick={toggleMenu} />
      <Nav isOpen={isOpen}>
        <ul>
          <li><Link to="/" onClick={closeMenu}>Home</Link></li>
          <li><Link to="/blog" onClick={closeMenu}>Blog</Link></li>
          <li><Link to="/quemsomos" onClick={closeMenu}>Quem Somos</Link></li>
          <li><Link to="/produtos" onClick={closeMenu}>Produtos</Link></li>
          <li><Link to="/artistas" onClick={closeMenu}>Artistas</Link></li>
          <li><Link to="/contato" onClick={closeMenu}>Contato</Link></li>
          <li><a href="https://anchor.fm/bardocast" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Bardocast</a></li>
        </ul>
      </Nav>
    </HeaderContainer>
  );
};

export default HeaderMobile;
