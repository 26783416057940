// firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Suas credenciais do Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBsuZF_15U4ZwlaiTOyPjO2LHOKnnSHOps",
    authDomain: "obardo-storage.firebaseapp.com",
    projectId: "obardo-storage",
    storageBucket: "obardo-storage.appspot.com",
    messagingSenderId: "1034969674660",
    appId: "1:1034969674660:web:518d0270e9a91c2a020731",
    measurementId: "G-EL7WZFCKGG"
  };

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);

// Exporta os serviços necessários
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
