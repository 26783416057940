import React from 'react';
import styled from 'styled-components';

const SiteLink = styled.a`
  display: block;
  padding: 15px;
  margin: 10px 0;
  background-color: #333;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  font-size: 1.2rem;
  &:hover {
    background-color: #555;
  }
`;

const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Sites = () => {
  return (
    <Gallery>
      <SiteLink href="https://www.obardoprodutora.com.br" target="_blank" rel="noopener noreferrer">
        O Bardo Produtora
      </SiteLink>
      
      <SiteLink href="https://ocarlosfelix-site.vercel.app/" target="_blank" rel="noopener noreferrer">
        Carlos Félix
      </SiteLink>
      
      <SiteLink href="https://www.bandagorros.com.br" target="_blank" rel="noopener noreferrer">
        Banda Gorros
      </SiteLink>
      
      <SiteLink href="https://www.appletie.com.br" target="_blank" rel="noopener noreferrer">
        AppleTie
      </SiteLink>
      
      <SiteLink href="https://www.geminiassistencia.com.br" target="_blank" rel="noopener noreferrer">
        Gemini Assistência
      </SiteLink>
      
      <SiteLink href="https://cph-ruddy.vercel.app/" target="_blank" rel="noopener noreferrer">
        Cachorrês para Humanos
      </SiteLink>
      
    </Gallery>
  );
};

export default Sites;
