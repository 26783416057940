import React from 'react';
import styled from 'styled-components';

const PrivacyContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const PolicyText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
`;

const PoliticaPrivacidade = () => {
  return (
    <PrivacyContainer>
      <Title>Política de Privacidade</Title>
      
      <Section>
        <PolicyText>
          Na <strong>O Bardo Produtora</strong>, respeitamos sua privacidade e estamos comprometidos em proteger as informações pessoais que você nos fornece. Coletamos dados pessoais apenas quando necessário, como nome e email, e usamos essas informações para aprimorar nossos serviços, responder a solicitações e enviar comunicações relevantes.
        </PolicyText>
      </Section>

      <Section>
        <h2>Coleta de Informações</h2>
        <PolicyText>
          Coletamos informações fornecidas voluntariamente por você, como nome e email, e também utilizamos cookies para melhorar a experiência de navegação.
        </PolicyText>
      </Section>

      <Section>
        <h2>Uso de Informações</h2>
        <PolicyText>
          Usamos suas informações para fins específicos, como envio de newsletters, atualização sobre eventos, ou para responder a solicitações. Não compartilhamos suas informações com terceiros, exceto quando exigido por lei.
        </PolicyText>
      </Section>

      <Section>
        <h2>Cookies</h2>
        <PolicyText>
          Usamos cookies para analisar o tráfego do site e melhorar sua navegação. Você pode desativar os cookies no seu navegador, mas isso pode impactar a funcionalidade do site.
        </PolicyText>
      </Section>

      <Section>
        <h2>Segurança</h2>
        <PolicyText>
          Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, alteração, ou divulgação indevida.
        </PolicyText>
      </Section>

      <Section>
        <h2>Seus Direitos</h2>
        <PolicyText>
          Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Entre em contato conosco para exercer esses direitos.
        </PolicyText>
      </Section>

      <Section>
        <h2>Contato</h2>
        <PolicyText>
          Se tiver dúvidas sobre esta política, entre em contato pelo email: <a href="mailto:seu-email-de-contato">seu-email-de-contato</a>.
        </PolicyText>
      </Section>
    </PrivacyContainer>
  );
};

export default PoliticaPrivacidade;
