import React, { useState } from 'react';
import styled from 'styled-components';
import IMG from './IMG'; // Componente de imagem reutilizável

// Estilos do modal principal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: #000;
  cursor: pointer;
`;

const EventTitle = styled.h2`
  color: #000;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
`;

const EventDescription = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
`;

// Estilos do modal de fotos ampliadas
const EnlargedImageModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const EnlargedImage = styled.img`
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 10px;
`;

const EventDetail = ({ event, onClose }) => {
  const { title, description, photos } = event; // Recebendo detalhes do evento via props
  const [enlargedPhoto, setEnlargedPhoto] = useState(null); // Estado para a foto ampliada

  const openEnlargedPhoto = (photo) => {
    setEnlargedPhoto(photo); // Abre a foto no modal ampliado
  };

  const closeEnlargedPhoto = () => {
    setEnlargedPhoto(null); // Fecha o modal de foto ampliada
  };

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <EventTitle>{title}</EventTitle>
          <EventDescription>{description}</EventDescription>
          <Gallery>
            {photos.map((photo, index) => (
              <IMG key={index} src={photo} alt={`Foto ${index + 1} do evento`} onClick={() => openEnlargedPhoto(photo)} />
            ))}
          </Gallery>
        </ModalContent>
      </ModalOverlay>

      {enlargedPhoto && (
        <EnlargedImageModal onClick={closeEnlargedPhoto}>
          <EnlargedImage src={enlargedPhoto} alt="Foto ampliada" />
          <CloseButton onClick={closeEnlargedPhoto}>&times;</CloseButton>
        </EnlargedImageModal>
      )}
    </>
  );
};

export default EventDetail;
