import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import useMediaQuery from './hooks/useMediaQuery';
import './App.css';

import HeaderDesktop from './components/HeaderDesktop';
import HeaderMobile from './components/HeaderMobile';
import Footer from './components/Footer';

import PrivateRoute from "./PrivateRoute";
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import BlogDashboard from './components/Dashboard/Blog/BlogDashboard';
import CreatePost from './components/Dashboard/Blog/CreatePost';
import UpdatePost from './components/Dashboard/Blog/UpdatePost';

import Home from './components/Home';
import QuemSomos from './components/QuemSomos';
import Contato from './components/Contato/Contato';
import Produtos from './components/Produtos';
import Artistas from './components/Artistas';
import Portfolio from './components/Portfolio/Portfolio';

import PoliticaPrivacidade from './components/PoliticaPrivacidade';
import Blog from './components/Blog/Blog';
import BlogPost from './components/Blog/BlogPost'; // Página do post individual

function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Router>
      <GlobalStyle />
      <div className="App">
        {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            {/* Rotas públicas */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/politica-de-privacidade" element={<PoliticaPrivacidade />} />
            <Route path="/post/:postId" element={<BlogPost />} /> {/* Detalhe de cada post */}
            <Route path="/quemsomos" element={<QuemSomos />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/produtos" element={<Produtos />} />
            <Route path="/artistas" element={<Artistas />} />
            <Route path="/portfolio" element={<Portfolio />} />

            {/* Rotas protegidas */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/blogDashboard"
              element={
                <PrivateRoute>
                  <BlogDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-post"
              element={
                <PrivateRoute>
                  <CreatePost />
                </PrivateRoute>
              }
            />
            <Route
              path="/update-post/:postId"
              element={
                <PrivateRoute>
                  <UpdatePost />
                </PrivateRoute>
              }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
