import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #000;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  font-family: 'Amatic SC', cursive;
  box-sizing: border-box; /* Garante que o padding não afete a largura */
`;

const FooterText = styled.p`
  font-size: 1rem;
  margin: 0;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 20px;
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: 1.5rem;

  &:hover {
    color: #f50; 
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>
        &copy; OBardo.dev - 2024
        </FooterText>
      <SocialLinks>
        <Link href="https://www.facebook.com/obardoproducoesmusicais" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FaFacebookF />
        </Link>
        <Link href="https://www.instagram.com/obardoprodutora" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </Link>
        <Link href="https://www.youtube.com/@obardoprodutora" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
          <FaYoutube />
        </Link>
      </SocialLinks>
    </FooterContainer>
  );
};

export default Footer;
