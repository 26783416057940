import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from "../../../Firebase";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const DashboardTitle = styled.h2`
  margin-bottom: 20px;
`;

const DashboardOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const OptionButton = styled.button`
  margin: 10px 0;
  padding: 15px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const SearchInput = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  width: 300px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const PostList = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
`;

const PostItem = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const PostCount = styled.p`
  font-size: 16px;
  margin-top: 20px;
`;

const NoPostsMessage = styled.p`
  font-size: 16px;
  color: red;
  margin-top: 20px;
`;

const BlogDashboard = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const navigate = useNavigate();

  // Carrega os últimos 5 posts ao montar o componente
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const q = query(collection(db, "posts"), orderBy("createdAt", "desc"), limit(5));
        const querySnapshot = await getDocs(q);

        const postsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(postsData);
        setFilteredPosts(postsData);

        // Pega o número total de posts
        setTotalPosts(querySnapshot.size); // Considera o snapshot obtido
      } catch (error) {
        console.error("Erro ao carregar posts:", error);
      }
    };

    fetchPosts();
  }, []);

  // Filtra os posts com base no termo de pesquisa
  useEffect(() => {
    if (searchTerm) {
      const filtered = posts.filter(post =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(posts);
    }
  }, [searchTerm, posts]);

  const handleCreatePost = () => {
    navigate("/create-post");
  };

  // Redireciona para a página de atualização de post
  const handlePostClick = (post) => {
    navigate(`/update-post/${post.id}`); // Redireciona para a página de update-post
  };

  return (
    <DashboardContainer>
      <DashboardTitle>Controle do Blog</DashboardTitle>
      
      <SearchInput
        type="text"
        placeholder="Buscar post pelo nome"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <PostList>
        {filteredPosts.length > 0 ? (
          filteredPosts.map(post => (
            <PostItem key={post.id} onClick={() => handlePostClick(post)}>
              {post.title}
            </PostItem>
          ))
        ) : (
          <NoPostsMessage>Nenhum post encontrado.</NoPostsMessage>
        )}
      </PostList>

      <OptionButton onClick={handleCreatePost}>Criar Novo Post</OptionButton>

      <PostCount>Total de posts: {totalPosts}</PostCount>
    </DashboardContainer>
  );
};

export default BlogDashboard;
